<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="选择园区"
        :rules="[{ required: true, message: '请选择园区', trigger: 'blur' }]"
        prop="communityId"
      >
        <community-select
          :disabled="form.id != null"
          v-model="form.communityId"
        />
      </el-form-item>

      <el-form-item
        label="选择商家"
        :rules="[{ required: true, message: '请选择商家', trigger: 'blur' }]"
        prop="shopId"
      >
        <v-select
          :disabled="form.id != null"
          :options="shopperList"
          v-model="form.shopId"
        />
      </el-form-item>
      <el-form-item
        label="商品分类"
        :rules="[
          { required: true, message: '请选择商品分类', trigger: 'change' },
        ]"
        prop="goodsClassify"
      >
        <v-select :options="goodsClassifyOps" v-model="form.goodsClassify" />
      </el-form-item>
      <el-form-item
        label="商品名称"
        :rules="[
          { required: true, message: '请输入商品名称', trigger: 'blur' },
        ]"
        prop="title"
      >
        <v-input
          placeholder="请输入商品名称"
          v-model="form.title"
          :width="250"
          :maxlength="50"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="商品类型"
        :rules="[
          { required: true, message: '请选择商品类型', trigger: 'blur' },
        ]"
        prop="goodsType"
      >
        <v-select :options="goodsTypeList" v-model="form.goodsType" />
      </el-form-item>
      <el-form-item
        label="封面图片"
        :rules="[
          { required: false, message: '请上传商品封面图片', trigger: 'blur' },
        ]"
        prop="imageUrl"
      >
        <div id="thumbImg">
          <v-upload :limit="1" :imgUrls.sync="form.imageUrl" />
        </div>
      </el-form-item>

      <el-form-item label="介绍图片">
        <div id="pictures">
          <v-upload :limit="9" :imgUrls.sync="form.pictures" />
        </div>
      </el-form-item>

      <el-form-item
        label="兑换所需积分"
        :rules="[
          { required: true, message: '请输入兑换所需积分', trigger: 'blur' },
        ]"
        prop="pointsPrice"
      >
        <v-input
          placeholder="请输入兑换所需积分值"
          v-model="form.pointsPrice"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="商品排序"
        :rules="[
          { required: true, message: '请输入商品排序值', trigger: 'blur' },
        ]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入商品排序值"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="状态"
        :rules="[{ required: true, message: '请选择状态', trigger: 'blur' }]"
        prop="shelfSts"
      >
        <v-select :options="shelfStsOptions" v-model="form.shelfSts" />
      </el-form-item>

      <el-form-item label="商品规格">
        <v-button
          size="medium"
          type="success"
          icon="el-icon-plus"
          text="添加规格"
          @click="showSkuAddView"
        >
        </v-button>
        <div style="width: 70%">
          <el-table
            class="el-table"
            :data="form.skuList"
            element-loading-text="Loading"
            border
            highlight-current-row
          >
            <el-table-column align="center" width="60">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column label="图片" align="center" width="200">
              <template slot-scope="scope">
                <el-image
                  v-if="scope.row.skuImageUrl"
                  style="width: 100px; height: 100px"
                  :src="scope.row.skuImageUrl"
                >
                </el-image>
              </template>
            </el-table-column>
            <el-table-column align="center" label="规格名称">
              <template slot-scope="scope">
                {{ scope.row.skuName }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="单人可兑换数量上限">
              <template slot-scope="scope">
                {{
                  scope.row.personLimitCnt == 0
                    ? "不限"
                    : scope.row.personLimitCnt
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="库存" width="100">
              <template slot-scope="scope">
                {{ scope.row.stock }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="排序" width="100">
              <template slot-scope="scope">
                {{ scope.row.sortIndex }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              label="操作"
              class-name="small-padding"
            >
              <template slot-scope="scope">
                <div class="dialogBottomBtn">
                  <v-button
                    type="primary"
                    size="mini"
                    v-if="scope.row.canEdit == undefined || scope.row.canEdit"
                    @click="showSkuEditView(scope.$index)"
                    text="编辑"
                  >
                  </v-button>
                  <v-button
                    size="mini"
                    type="danger"
                    @click="deleteSkuTempData(scope.$index)"
                    text="删除"
                  >
                  </v-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>

      <el-form-item
        label="详情内容"
        :rules="[
          { required: true, message: '请输入商品内容', trigger: 'blur' },
        ]"
        prop="detailContent"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.detailContent" />
        </div>
      </el-form-item>
    </form-panel>

    <el-dialog
      :close-on-click-modal="false"
      title="商品规格信息编辑"
      :visible.sync="dialogFormVisible"
      custom-class="el-dialog-largeWidth"
      @close="close"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="规格图片">
          <div id="skuImg">
            <v-upload :limit="1" :imgUrls.sync="skuObj.skuImageUrl" />
          </div>
        </el-form-item>
        <el-form-item label="规格名称">
          <v-input v-model="skuObj.skuName" :maxlength="20"></v-input>
        </el-form-item>
        <el-form-item label="库存">
          <v-input v-model="skuObj.stock" type="number"></v-input>
        </el-form-item>
        <el-form-item label="单人可换数量" class="personLimitCnt">
          <v-input
            placeholder="请输入单人可换数量"
            v-model="skuObj.personLimitCnt"
            :width="150"
            min="0"
            type="number"
          ></v-input>
          <span class="tips">(不填写或者填写为0时，不限制兑换数量)</span>
        </el-form-item>
        <el-form-item label="排序">
          <v-input v-model="skuObj.sortIndex" type="number"></v-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="medium" @click="cancel">取 消</el-button>
        <el-button size="medium" type="primary" @click="saveTempSkuData"
          >确 定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  activityDetail,
  activityEdit,
  activityPointSettingList,
  getShopperListUrl,
} from "./api.js";
import { PointsMallGoodsDetail, PointsMallGoodsEdit } from "./api";
import { shelfSts, goodsTypeList, setGoodsClassifyOps } from "./map";

export default {
  name: "goodsEdit",
  data() {
    return {
      goodsTypeList,
      shopperList: [],
      goodsClassifyOps: setGoodsClassifyOps(),
      submitConfig: {
        queryUrl: PointsMallGoodsDetail,
        submitUrl: PointsMallGoodsEdit,
      },
      form: {
        id: null,
        title: "",
        subImg: "",
        imageUrl: "",
        subPictures: "",
        goodsClassify: undefined,
        pictures: "",
        pointsPrice: null,
        sortIndex: 0,
        shelfSts: 0,
        detailContent: "",
        goodsType: "",
        skuList: [],
        shopId: null,
        adminId: null,
        communityId: null,
      },
      communityId: null,
      shelfStsOptions: shelfSts,

      dialogFormVisible: false,
      skuObj: {
        index: null,
        id: null,
        goodsId: null,
        skuName: null,
        skuImageUrl: null,
        sortIndex: 0,
        stock: 0,
      },
    };
  },
  mounted() {
    this.getShopperList();

    const { id, communityId } = this.$route.query;
    this.form.communityId = communityId;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    } else {
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    close() {
      console.log(899);
      this.init();
    },
    init() {
      this.skuObj = {
        index: null,
        id: null,
        goodsId: null,
        skuName: null,
        skuImageUrl: null,
        sortIndex: 0,
        stock: 0,
      };
    },
    cancel() {
      this.dialogFormVisible = false;
    },

    getShopperList() {
      this.shopperList = [];
      this.$axios.get(`${getShopperListUrl}`).then((res) => {
        if (res.code === 200) {
          res.data.forEach((item) => {
            item.label = item.title;
            item.value = item.id;
            this.shopperList.push(item);
          });
        } else {
        }
      });
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      if (this.form.imageUrl) {
        this.form.subImg = this.form.imageUrl;
      }
      if (this.form.pictures) {
        this.form.subPictures = this.form.pictures;
      }
    },
    submitBefore() {
      if (!this.form.imageUrl || this.form.imageUrl.length == 0) {
        this.$message.error("请上传商品封面图片");
        return;
      }
      if (!this.form.pictures || this.form.pictures.length == 0) {
        this.$message.error("请上传商品介绍图片");
        return;
      }

      if (
        !this.form.pointsPrice ||
        !/(^[1-9]\d*$)/.test(this.form.pointsPrice)
      ) {
        this.$message.error("请输入兑换商品所需的正确积分值");
        return;
      }

      if (!this.form.skuList || this.form.skuList.length == 0) {
        this.$message.error("请编辑商品的规格数据");
        return;
      }

      if (!this.form.adminId) {
        this.form.adminId = this.$store.state.app.userInfo.id;
      }

      return true;
    },
    submitSuccess() {
      return true;
    },

    showSkuAddView() {
      this.skuObj = {
        index: null,
        id: null,
        goodsId: null,
        skuName: null,
        skuImageUrl: "",
        skuImg: "",
        sortIndex: 0,
        stock: 0,
        personLimitCnt: 0,
      };

      this.dialogFormVisible = true;
    },

    showSkuEditView(index) {
      let data = this.form.skuList[index];

      this.dialogFormVisible = true;
      this.skuObj = {
        index: index,
        id: data.id,
        goodsId: data.goodsId,
        skuName: data.skuName,
        skuImageUrl: data.skuImageUrl,
        skuImg: data.skuImageUrl,
        sortIndex: data.sortIndex,
        stock: data.stock,
        personLimitCnt: data.personLimitCnt,
      };
    },
    deleteSkuTempData(index) {
      let data = this.form.skuList[index];
      this.$confirm("确定要删除" + data.skuName + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.form.skuList.splice(index, 1);
        })
        .catch(() => {});
    },
    saveTempSkuData() {
      if (!this.skuObj.skuName) {
        this.$message.error("请输入规格名称");
        return;
      }
      if (!this.skuObj.skuImageUrl) {
        this.$message.error("请上传规格图片");
        return;
      }
      if (!this.skuObj.stock) {
        this.$message.error("请输入规格库存");
        return;
      }

      if (this.skuObj.index == null) {
        this.skuObj["index"] = this.form.skuList.length;
        this.form.skuList.push(this.skuObj);
      } else {
        this.$set(this.form.skuList, this.skuObj.index, this.skuObj);
      }
      this.dialogFormVisible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .dialogBottomBtn {
    display: flex;
    flex-direction: column;
  }
}
</style>
<style lang="less">
.divBox {
  box-sizing: border-box;
  height: 100%;
  .personLimitCnt {
    position: relative;
    .tips {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      color: red;
    }
  }
  .dialogBottomBtn {
    .v-button {
      margin-bottom: 10px;
    }
  }
}
</style>
